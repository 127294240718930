$primary-color: #00597b;
$accent-color: #1a79a4;
$warn-color: #a42200;

$jgrants-blue: $accent-color;
$jgrants-yellow: #f7b802;
$jgrants-red: $warn-color;

$jgrants-card-shadow: 0px 2px 10px -1px rgba(0, 0, 0, 0.2), 0px 1px 10px 0px rgba(0, 0, 0, 0.14),
  0px 1px 3px 0px rgba(0, 0, 0, 0.12);

$jgrants-text-color: #1a1a1a;
$jgrants-text-sub-color: #5f6368;

$jgrants-button-text-color: #ffffff;

$jgrants-default-font-size: 16px;
$jgrants-default-line-height: 24px;
$jgrants-default-font-weight: 400;

$jgrants-background-color: #f2f2f2;
$jgrants-viewer-backgroud-color: #f4f4f4;

$jgrants-content-max-width: 1000px;
$jgrants-link-hover-color: #be4900;

$jgrants-importance-color: #ffebde;
$jgrants-for-beginner-color: #edf6f8;
$jgrants-highlight-color: #c04a00;
$jgrants-background-color-delegate: #E6E6FA;

@mixin jgrants-mat-table($color: $accent-color, $header-font-size: 1rem) {
  width: 100%;

  .mat-mdc-header-row {
    background-color: $color;
    border-radius: 2px 2px 0 0;
  }

  .mat-mdc-header-cell {
    font-size: $header-font-size;
    font-weight: bold;
    color: white;
  }

  ::ng-deep .mat-mdc-cell {
    font-size: 0.8rem;
    font-weight: 600;
    color: #3f404f;
  }

  ::ng-deep .mat-mdc-sort-header-arrow {
    color: #c1c1c1;
  }

  .table-column {
    padding-right: 0.5rem;

    // ソート用の上下の矢印を含めるため最低二文字分の幅
    min-width: 2rem;

    .table-cell {
      padding: 0 0 0 0.5rem;
      overflow: hidden;
      white-space: normal;
      text-overflow: ellipsis;

      &__align-center {
        width: 100%;
        text-align: center;
      }

      &__align-right {
        width: 100%;
        text-align: right;
      }
    }
  }
}

.mat-expansion-panel-header {
  font-size: 15px;
}

::ng-deep .mat-expansion-panel-content {
  font-size: 16px;
}

.dialog-outline {
  padding: 10px 24px;
}

.delegation-icon {
  padding: 2px 1rem;
  margin: 10px 0 5px 0;
  white-space: pre-wrap;
  background-color: $primary-color;
  color: white;
  width: 4rem;
  font-weight: normal;
}